import {isoDocument} from './document';
import {isoWindow} from './window';

// Source: http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html

export const calculatePopupCenter = (
  windoidWidth: number,
  windoidHeight: number,
) => {
  const dualScreenLeft =
    isoWindow.screenLeft === undefined
      ? isoWindow.screenX
      : isoWindow.screenLeft;
  const dualScreenTop =
    isoWindow.screenTop === undefined ? isoWindow.screenY : isoWindow.screenTop;

  let width;
  if (isoWindow.innerWidth) {
    width = isoWindow.innerWidth;
  } else if (isoDocument.documentElement.clientWidth) {
    width = isoDocument.documentElement.clientWidth;
  } else {
    width = screen.width;
  }

  let height;
  if (isoWindow.innerHeight) {
    height = isoWindow.innerHeight;
  } else if (isoDocument.documentElement.clientHeight) {
    height = isoDocument.documentElement.clientHeight;
  } else {
    height = screen.height;
  }

  const systemZoom = Math.max(1, width / isoWindow.screen.availWidth);
  const left = (width - windoidWidth) / 2 / systemZoom + dualScreenLeft;
  const top = (height - windoidHeight) / 2 / systemZoom + dualScreenTop;

  return {
    height: windoidHeight / systemZoom,
    left,
    top,
    width: windoidWidth / systemZoom,
  };
};

const handlePageClose = (windoidRef: Window | null) => {
  windoidRef?.close();
};

export const openWindoid = (
  authorizeUrl: string,
  messageCallback: (payload: MessageEvent) => void,
): Window | null => {
  const values = calculatePopupCenter(365, 554);

  const windoidRef = isoWindow.open(
    authorizeUrl,
    'SignInWithShop',
    `popup,width=${values.width},height=${values.height},top=${values.top},left=${values.left}`,
  );

  // This is to handle the case when the user closes the window or navigates to a different page
  isoWindow.addEventListener(
    'beforeunload',
    () => handlePageClose(windoidRef),
    {
      once: true,
    },
  );
  isoWindow.addEventListener('unload', () => handlePageClose(windoidRef), {
    once: true,
  });
  isoWindow.addEventListener('pagehide', () => handlePageClose(windoidRef), {
    once: true,
  });

  isoWindow.addEventListener('message', messageCallback);
  return windoidRef;
};
